import React from 'react';
import _ from 'lodash';
import s from './ProductPrice.scss';
import { useControllerProps } from '../../ControllerContext';
import { useState } from '../../stores';

const ProductPrice = () => {
  const {
    state: { checkout },
  } = useState();
  const { product, currencyCode, locale } = useControllerProps().giftCardStore;

  const formatCurrency = (value: number) => {
    try {
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return formatter.format(value);
    } catch (error) {
      return value.toString();
    }
  };

  const getPrice = () => {
    if (checkout.purchaseOptions.variantId) {
      const selectedVariant = _.find(product?.variants, {
        id: checkout.purchaseOptions.variantId,
      });
      const selectedVariantPrice = selectedVariant?.price;

      return formatCurrency(Number(selectedVariantPrice));
    } else {
      return getPriceRange();
    }
  };

  const getPriceRange = () => {
    const minPriceVariant = _.minBy(product?.variants, 'price');
    const maxPriceVariant = _.maxBy(product?.variants, 'price');

    if (!minPriceVariant || !maxPriceVariant) {
      return formatCurrency(0);
    }

    const minPrice = minPriceVariant.price;
    const maxPrice = maxPriceVariant.price;

    if (minPrice === maxPrice) {
      return formatCurrency(Number(minPrice));
    }

    return `${formatCurrency(Number(minPrice))} - ${formatCurrency(
      Number(maxPrice),
    )}`;
  };

  return (
    <h6
      data-hook="rise-gift-card-product-price"
      className={s.price}
      dangerouslySetInnerHTML={{
        __html: getPrice(),
      }}
    />
  );
};
export default ProductPrice;
