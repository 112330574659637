import {
  createSettingsParams,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';

import { Alignments } from './Settings/Components/Alignment/Alignment';

export type ISettingsParams = {
  descriptionLimitRows: SettingsParamType.Number;
  deliveryDate: SettingsParamType.Boolean;
  quantity: SettingsParamType.Boolean;
  productTitleAlignment: SettingsParamType.String;
  descriptionAlignment: SettingsParamType.String;
  buyButtonText: SettingsParamType.Text;
  amountLabel: SettingsParamType.Text;
  recipientEmailLabel: SettingsParamType.Text;
  deliveryDateLabel: SettingsParamType.Text;
  deliveryDateDefaultText: SettingsParamType.Text;
  recipientNameLabel: SettingsParamType.Text;
  giftMessageLabel: SettingsParamType.Text;
  quantityLabel: SettingsParamType.Text;
  descriptionReadMoreButton: SettingsParamType.Text;
  descriptionReadLessButton: SettingsParamType.Text;
  amountErrorMessage: SettingsParamType.Text;
  recipientEmailErrorMessage: SettingsParamType.Text;
  srDeliveryDateChooseDateButton: SettingsParamType.Text;
  srQuantityRemoveQuantity: SettingsParamType.Text;
  srQuantityTotalQuantity: SettingsParamType.Text;
  srQuantityAddQuantity: SettingsParamType.Text;
  quantityErrorMessage: SettingsParamType.Text;
  giftCardForLabel: SettingsParamType.Text;
  someoneElseButton: SettingsParamType.Text;
  myselfButton: SettingsParamType.Text;
  giftCardFor: SettingsParamType.Text;
};

export default createSettingsParams<ISettingsParams>({
  descriptionLimitRows: {
    type: SettingsParamType.Number,
    getDefaultValue: () => 3,
  },
  productTitleAlignment: {
    type: SettingsParamType.String,
    getDefaultValue: () => Alignments.LEFT,
  },
  descriptionAlignment: {
    type: SettingsParamType.String,
    getDefaultValue: () => Alignments.LEFT,
  },
  deliveryDate: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  quantity: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  buyButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.buyNow.button')}`,
  },
  amountLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.amount.label')}`,
  },
  recipientEmailLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.recipientEmail.label')}`,
  },
  deliveryDateLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.deliveryDate.label')}`,
  },
  deliveryDateDefaultText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.deliveryDate.defaultText')}`,
  },
  recipientNameLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.recipientName.label')}`,
  },
  giftMessageLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.giftMessage.label')}`,
  },
  quantityLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.quantity.label')}`,
  },
  descriptionReadMoreButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('giftCardPage.description.readMore.button')}`,
  },
  descriptionReadLessButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('giftCardPage.description.readLess.button')}`,
  },
  amountErrorMessage: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.amount.errorMessage')}`,
  },
  recipientEmailErrorMessage: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('giftCardPage.recipientEmail.errorMessage')}`,
  },
  srDeliveryDateChooseDateButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('giftCardPage.sr.deliveryDate.chooseDate.button')}`,
  },
  srQuantityRemoveQuantity: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('giftCardPage.sr.quantity.removeQuantity')}`,
  },
  srQuantityTotalQuantity: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('giftCardPage.sr.quantity.totalQuantity')}`,
  },
  srQuantityAddQuantity: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.sr.quantity.addQuantity')}`,
  },
  quantityErrorMessage: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.quantity.errorMessage')}`,
  },
  giftCardForLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.giftCardFor.label')}`,
  },
  someoneElseButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.someoneElse.button')}`,
  },
  myselfButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('giftCardPage.myself.button')}`,
  },
  giftCardFor: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `both`,
  },
});
